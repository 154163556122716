<template>
  <div class="flex-column-center">
    <div class="mbox">
      <div v-for="(m, inx) in messages" :key="inx" class="flex mrow">
        <div>{{m.role}}：</div>
        <div class="flex-1">{{m.content}}</div>
      </div>
    </div>

    <div class="flex-center row-bar">
      <div>状态:{{connected ? '已连接' : '未连接'}}</div>
      <el-button type="primary" @click="connect" v-if="!connected">连接</el-button>
      <el-button type="primary" @click="close" v-if="connected">断开</el-button>
    </div>

    <div class="flex-center row-bar" v-if="connected">
      <el-input v-model="ipId" placeholder="角色id" />
      <el-input v-model="functionId" placeholder="技能id" />
      <el-input v-model="nickname" placeholder="昵称" />
      <el-button type="primary" @click="createSession">创建session</el-button>
    </div>

    <div class="flex-center row-bar" v-if="connected && local && sessionOpened">
      <el-button type="primary" :loading="recording" @click="startRecorder">开始ASR</el-button>
      <el-button type="primary" @click="stopRecorder">停止ASR</el-button>
    </div>

    <div class="flex-center row-bar" v-if="connected && sessionOpened">
      <el-upload
        class="avatar-uploader"
        :show-file-list="false"
        :before-upload="beforeUpload"
      >
        <img v-if="fileURL" :src="fileURL" class="avatar">
        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
      </el-upload>
      <el-input v-model="content" />
      <el-button type="primary" @click="sendContent">发送纯文本</el-button>
      <el-button type="primary" @click="sendImage">发送图文</el-button>
    </div>
  </div>
</template>

<script>
import { ASRRecorder } from '@/commons/recorder.js'
import md5 from 'md5'

export default {
  data() {
    return {
      connected: false,
      recording: false,
      content: '',
      messages: [],
      ipId: 1,
      functionId: '',
      nickname: '光头强',

      file: '',
      fileURL: '',

      sessionId: null,
      sessionOpened: false,
      local: location.origin.indexOf('localhost') !== -1
    }
  },

  created() {
    this.initRecorder()
  },

  beforeUnmount() {
    this.close()
    this.stopPingTimer()
  },

  methods: {
    close() {
      if (this.socket) {
        this.socket.close()
        this.socket = null
      }
    },

    connect() {

      const key = 'test'
      const token = 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoidGVzdDAwMSIsImRldmljZV9pZCI6ImRldmljZTAwMSIsImlhdCI6MTcyMTAyMTMwNCwiZXhwIjoxNzI5NjYxMzA0LCJpc3MiOiIvY2xpZW50In0.RjClkMWF493rW9i2k3s68F7yuH-LMsF4blp9kKqc2pg'
      const params = [
        `x-key=${key}`,
        `x-service=chat`,
        `x-version=1.0`,
        `x-time=${parseInt(Date.now()/1000)}`,
      ]
      const plainText = params.join('&')
      const sign = md5(plainText)

      params.push(`x-sign=${sign}`)
      params.push(`x-client-type=web`)
      params.push(`x-client-id=TESTCLIENT`)
      params.push(`authorization=${encodeURIComponent(token)}`)

      let host = 'ws://localhost:8090'
      host = 'wss://lingos.test.ling.space'
      const socket = this.socket = new WebSocket(`${host}/ws/chat?${params.join('&')}`)
      socket.onopen = (e) => {
        console.log('onopen')
        this.connected = true
        this.ping()
      }

      socket.onerror = (e) => {
        console.log('onerror', e)
      }

      socket.onmessage = ({ data }) => {
        console.log('onmessage', data)
        data = JSON.parse(data)
        switch (data.event) {
          case 'session':
            this.onSession(data)
            break
          case 'message_delta':
            this.onMessageDelta(data)
            break
          case 'message_asr_end':
            this.onASREnd(data)
            break
          case 'pong':
            console.log('pong', data)
            break
          default:
        }
      }

      socket.onclose = (e) => {
        console.log('onclose')
        this.connected = false
        this.sessionOpened = false
        this.stopPingTimer()
      }
      console.log('-------')
    },

    onMessageDelta(data) {
      let last = this.messages[this.messages.length - 1]
      if (!last || last.id !== data.data.message_id) {
        last = {
          id: data.data.message_id,
          role: 'assistant',
          content: ''
        }
        this.messages.push(last)
      }
      last.content = `${last.content}${data.data.content}`
    },

    stopPingTimer() {
      if (this.pingTimer) clearTimeout(this.pingTimer)
      this.pingTimer = null
    },

    ping() {
      this.stopPingTimer()
      this.pingTimer = setTimeout(() => {
        if (this.connected) {
          const uuid = `timestamp${Date.now()}`
          const msg = JSON.stringify({
            uuid,
            event: 'ping',
            data: {}
          })
          this.socket.send(`${msg}\n`)

          this.ping()
        }
      }, 10000)
    },

    initRecorder() {
      this.recorder = new ASRRecorder({
        ondata: (data) => {
          console.log('recorder send')
          if (!this.recording) return
          // this.socket.emit('asr', { data })

          const msg = JSON.stringify({
            uuid: this.messageUUID,
            event: 'message_asr_data',
            data: {
              attributes: {
                data
              }
            }
          })
          this.socket.send(`${msg}\n`)
          console.log('send')
        },

        onend: () => {
          console.log('recorder onend')
        }
      })
    },

    startRecorder() {
      console.log('startRecorder')
      // this.socket.emit('asr.init', { "auto": true, type, text: this.pronounceText, ...(params || {}) })
      const uuid = this.messageUUID = `timestamp${Date.now()}`
      const data = JSON.stringify({
        uuid,
        event: 'message',
        data: {
          type: 'text',
          attributes: {
            asr: true
          }
        }
      })
      this.socket.send(`${data}\n`)

      const data2 = JSON.stringify({
        uuid,
        event: 'message_asr_start',
        data: {
          attributes: {
            auto: true
          }
        }
      })
      this.socket.send(`${data2}\n`)

      this.recorder.start()
      this.recording = true
    },

    onASREnd() {
      this.stopRecorder()
    },

    stopRecorder() {
      this.doStopRecorder()
      this.sendStop()
    },

    doStopRecorder() {
      console.log('stopRecorder')
      this.recording = false
      this.recorder.stop()
    },

    sendStop() {
      const uuid = this.messageUUID = `timestamp${Date.now()}`
      const data = JSON.stringify({
        uuid,
        event: 'message_asr_end',
        data: {

        }
      })
      this.socket.send(`${data}\n`)
    },

    uuid() {
      return `id${Date.now()}`
    },

    createSession() {
      const data = JSON.stringify({
        uuid: this.uuid(),
        event: 'session',
        data: {
          type: 'text',
          attributes: {
            ip_id: this.ipId,
            function_id: this.functionId || null,
            nickname: this.nickname,
            session_id: this.sessionId,
            params: { a: 1 }
            // needs_tts: false
          }
        }
      })
      this.socket.send(`${data}\n`)
    },

    onSession(data) {
      if (data.errno) {
        console.error('session事件异常', data.errmsg, data.debug)
        return
      }
      this.sessionOpened = true
      this.sessionId = data.data.session_id
    },

    sendContent() {
      if (!this.content) return
      const content = this.content
      this.content = ''

      const data = JSON.stringify({
        uuid: this.uuid(),
        event: 'message',
        data: {
          type: 'text',
          attributes: {
            asr: false,
            content,
            // needs_tts: false
          }
        }
      })
      this.socket.send(`${data}\n`)
      this.messages.push({
        role: 'user',
        content
      })
    },

    sendImage() {
      if (!this.content) return
      if (!this.file) return

      const { content, file } = this
      this.content = ''

      this.file = null
      this.fileURL = null

      const data = JSON.stringify({
        uuid: this.uuid(),
        event: 'message',
        data: {
          type: 'text',
          attributes: {
            asr: false,
            content,
            files: [
              { type: 'image', 'transfer_type': 'base64', url: file, format: 'png' }
            ]
          }
        }
      })
      this.socket.send(`${data}\n`)
      this.messages.push({
        role: 'user',
        content
      })
    },

    async whenSelected(file) {
      const base64 = await this.file2base64(file)
      this.fileURL = base64
      this.file = base64.replace(/data:image\/\w+;base64,/, '')
    },

    file2base64(file) {
      return new Promise((r, j) => {
        try {
          var reader = new FileReader()
          reader.readAsDataURL(file)
          reader.onload = function () {
            r(reader.result)
          }
          reader.onerror = function (error) {
            j(error)
          }
        } catch(e) {
          j(e)
        }
      })
    },

    beforeUpload(file) {
      console.log(file)
      this.whenSelected(file)
      return false
    }
  }
}
</script>

<style scoped>
.row-bar{
  padding:12px 0;
}
.row-bar > * {
  margin:0 12px;
}

.mbox{
  margin:6px;
  border-radius:6px;
  border:1px solid rgba(0,0,0,0.6);
  width:500px;
  min-height:100px;
  max-height:500px;
  overflow:auto;
}

.mrow{
  line-height:24px;
  padding:0 6px;
}

.avatar-uploader{
  width: 64px;
  height: 64px;
  border-radius: 5px;
  border: 1px dashed #ccc;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}

.avatar-uploader img {
  max-width:64px;
  max-height:64px;
}
</style>
